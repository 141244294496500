body {
  /*background-color: rgb(241, 241, 241);
  background-image: url(./imagens//fundo2.jpg); */
  background-attachment: fixed;
}
.App {
  display: flex ;
}

/* Header 
header {display:table; width:100%; padding-top:30px}
header .container {position:relative;}
header .row {display:flex; align-items:center;}
header .logo {display:table; margin:0 auto; border-radius: 4px; height:230px; background:#8a1d21; border:5px #fff solid; width:100%; position:relative; margin-bottom: -20px; box-shadow: 0px 0px 10px #0000001c;}
header .logo img {position:absolute; top:0px; bottom:0px; left:0px; right:0px; margin:auto; max-width:80%; max-height:80%; display:table;}
header p {margin-top:20px; font-size:12px; margin-bottom:0px;} */

#textohome1{
  font-family: 'Poppins', sans-serif;
  font-size: x-large;
  margin-top: 100px;
  text-align: center;
}

#textohome2{
  font-family: 'Poppins', sans-serif;
  font-size: large;
  margin-top: 100px;
  text-align: center;
}

#textohome3{
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  margin-top: 100px;
  text-align: center;
  text-decoration-line: underline;
}

.linha_foto{
  display: table;
  width: 100%;  
  padding-top: 25px;
  padding-bottom: 25px;
  /*background-image: url('https://firebasestorage.googleapis.com/v0/b/pj-cardapio.appspot.com/o/lojas%2Fdemoloja%2Fbackground_image%2Fbackground.jpg?alt=media&token=7a2b5f03-678e-4aee-8d34-ed1154d89ec6');*/
  background-size: cover;
}

.linha{
  display: table;
  width: 100%;  
  padding-top: 25px;
  padding-bottom: 25px;
}

.grupoHeader {
  margin-left: 20px;
  display: flex;
}

.App-logo {
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}

.textoHeader {
  padding-left: 10px;
  color: #FFF;
  z-index: 1;
  position: relative;
}

.corpo {
  margin-left: 10px;  
  margin-right: 10px;
}

#informacao {
  margin-left: 5px;  
  margin-right: 5px;
  margin-top: 5px;
}

.sessao {
  margin-top: 10px;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 10px;
}

#categoria {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  border-radius: 5px;
  text-decoration-line: underline;
  text-decoration-color: #00a808;
}

.linhaProduto {
  display: flex;
  width: 100%;
  border: #282c34;
  border-width: 1px;
  border-style: solid;
}

#titProduto {
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}

#descProduto {
  font-size: 13px;
  line-height: 13px;
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
}

#imgProduto {
  max-width: 80px;
  max-height: 80px;
}

.qtdeProduto {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#tituloHeaderHrFunc {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #f37377;
  text-align: center;
}
#textoHrFunc {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}


/* CSS da escolha dos complementos */
#comp-linha{
  height: 10px;
}

#tabelaT{
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
}

#textoQtdeProduto {
  text-align: center;
  color: #f37377;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

#textoPrecoProdutoAPartir {
  text-align: left;
  color: #f37377;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

#gridHorario{
  padding: 20px;
}

#horarioHeader{
  display: flex;  
  margin-bottom: 10px;
}

#tituloHeader{
  font-size: 20px;
  font-weight: bold;
  color: #f37377;
}

#horarioSemana{
  margin-top: 10px;
  margin-bottom: 10px;  
}

#diaSemana{
  font-weight: bold;
}

#horaSemana{
  font-size: small;
  margin-top: 5px;
}

/*estilos da janela de complementos*/

#boxNomeComplemento{
  background-color: #00FFFF;
  padding: 20px;
  height: 70px;
}
#dialogConteudo{
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
}
#listaComplementos{
  padding: 5px;
}

#complementoNomeProduto{
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

#complementoDescricaoProduto{
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  line-height: 15px;
}
#precoComplemento{
  background-color: ivory;
  padding: 2px;
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
}

#headerPedido{
  height: 100px;
}

#divcor{
  border-top: 1px dashed #DCDCDC;
}

#boxValorTotal{
  background-color: #666;
  padding: 15px;
  color: #fff;  
}

#corpoPedido{
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
}

#prodNome{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

#prodQtde{
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 300;
}

#prodPreco{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #00a808;  
}

#textoValorTotal{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
}

#seuPedido{
  display: flex;
  font-size: 20px;
  font-family: Roboto;
  font-weight: bold;

}

.textoPedido{
  font-size: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#tituloSessao{
  margin-top: 60px;
  margin-bottom: 15px;
  text-align: center;
  font-weight: bold;
}

#taxaEntrega{
  font-size: 12px;
  color: #666;
}

#valorTaxaEntrega{
  font-size: 15px;
  color: #666;
  font-weight: bold;
}

#tituloEndRetirada{
  font-size: 20px;
  color: #c32c31;
  font-weight: bold;
}

#endRetirada{
  font-size: 15px;
}

#textoCestaVazial1{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
}
#textoCestaVazial2{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}

.rodape{
  padding: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#logoempresa{
  height: 40px;
  width: auto;
  margin-right: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo_old {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
